import React from 'react';
import moment from 'moment';
const Archives = ({posts}) => {
    const data = [...new Set(posts.map(p => moment(p.date).format('MMMM YYYY')))];
    return (
        <div className='p-4'>
            <h4 className='fst-italic'>Archives</h4>
            <ol className='list-unstyled mb-0'>
                {data?.length > 0 &&
                    data.map((d,i) => {
                        return <li key={i}><a href='/'>{d}</a></li>
                    })
                }                
            </ol>            
        </div>
    )
};
        
export default Archives;