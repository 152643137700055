import React, {useState, useEffect} from 'react';
import Header from '../../shared/header';
import Footer from '../../shared/footer';
import { useParams } from 'react-router-dom';
import Posts from './posts';

function ViewByCategory({categories, posts}) {
  const {categoryName } = useParams();
  const [filteredPosts, setFilteredPosts] = useState({});
  
  useEffect(() =>{
    const findPostsByCategoryName = () =>{
      let items = posts.filter(post => {
        return post.categories?.filter(category => {
          return category.Slug === categoryName
        })?.length > 0
      });
      if (items){
        setFilteredPosts(items);
      }
    };
    if (posts?.length > 0 && categoryName){
      findPostsByCategoryName();
    } else {
      console.log('no categories found');
    }
  },[posts, categoryName]);
  
  return (
    <>
      <Header categories={categories}/>      
      <section className='section-padding'>
        <div className='container'>
          <div className='row'>
            { filteredPosts && categoryName &&
            <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
              <Posts posts={filteredPosts} label={`Posts matching category of ${categoryName}`} />
            </div>
            }
          </div>
        </div>
      </section>
      <Footer/>
    </>
  );
}

export default ViewByCategory;
