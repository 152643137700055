import React, {useState, useEffect} from 'react';
import Header from '../../shared/header';
import Footer from '../../shared/footer';
import { useParams } from 'react-router-dom';
import Posts from './posts';

function ViewByQuery({categories, posts}) {
  const { query } = useParams();
  const [filteredPosts, setFilteredPosts] = useState({});
  
  useEffect(() =>{
    const findPostsByQuery = () =>{
      let items = posts.filter(post => {
        return post.tags?.find((tag) => tag?.Name.toLowerCase().includes(query.toLowerCase())) || post.title.toLowerCase().includes(query.toLowerCase());
      });
      if (items){
        setFilteredPosts(items);
      }
    };
    if (posts?.length > 0 && query){
      findPostsByQuery();
    } else {
      console.log('no search items found');
    }
  },[posts, query]);
  
  return (
    <>
      <Header categories={categories}/>      
      <section className='section-padding'>
        <div className='container'>
          <div className='row'>
            { filteredPosts && query &&
            <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
              <Posts posts={filteredPosts} label={`Posts matching search of ${query}`} />
            </div>
            }
          </div>
        </div>
      </section>
      <Footer/>
    </>
  );
}

export default ViewByQuery;
