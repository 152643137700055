import React, { Component } from 'react';
import moment from 'moment';
class Footer extends Component {
  render(){
    return (
        <footer className='footer-section bg-grey d-print-none'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-12 text-center'>
                        <p className='copyright'>🚀 {moment().format('YYYY')} - Tyler Merrick</p>
                    </div>
                </div>
            </div>
        </footer>
    );
  }
}

export default Footer;