import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';

const PostSummary = ({post}) => {
    return (
        <div className='col-lg-4 col-md-6 col-sm-6'>
            <article className='post-grid mb-5'>
                <Link className='post-thumb mb-4 d-block' to={post.url}>
                    <img src={`https://www.tylermerrick.com/static/blog/thumbnail/${post.thumbnail}`} className='img-fluid w-100' alt={post.title} />
                </Link>

                <div className='post-content-grid'>
                    <div className='label-date'>
                        <span className='day'>{moment(post.date).format('DD')}</span>
                        <span className='month text-uppercase'>{moment(post.date).format('MMM')}</span>
                    </div>
                    {post && post.categories?.length > 0 &&
                    <>
                    {
                        post.categories?.map((category,index) => {
                            return <span key={index}>{(index > 0 && ', ')}
                                <Link to ={`/blog/category/${category.Slug}`} 
                                    className='post-category font-extra text-color text-uppercase font-sm letter-spacing-1'>{category.Name}</Link>
                                </span>
                        })
                    }
                    </>
                    }
                    <h3 className='post-title mt-1'>
                        <Link to={post.url}>{post.title}</Link>
                    </h3>
                    <p dangerouslySetInnerHTML={{__html: post.summary}}></p>
                </div>
            </article>  
        </div>
    )
};

export default PostSummary;