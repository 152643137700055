import axios from 'axios';
const baseUrl = 'https://www.tylermerrick.com/api';

class Api {
  static async getCategories() {
    try {
      const response = await axios.get(`${baseUrl}/Blog/Category`);
      return response.data;
    } catch(error){
      console.log(error);
    }   
  }
  static async getPosts() {
    try {
      const response = await axios.get(`${baseUrl}/Blog/Post`);
      return response.data;
    } catch(error){
      console.log(error);
    }    
  }
  static async getPost(id) {
    try {
      const response = await axios.get(`${baseUrl}/Blog/Post/${id}`);
      return response.data;
    } catch(error){
      console.log(error);
    }    
  }
}

export default Api;